@media print
{
	.no-print {
		display:none;
	}
    .document .selection-actions a.sidebar-actions,
    .document .in-line-attachments .actions {
        display: none !important;
    }
	.modal.modal-drawer.fade.in {
		top: 0;
		height: 100%;
	}
	.modal-align {
		width: 100%;
		max-width: 100%;
	}
	.modal.modal-drawer {
		overflow-y: visible;
	}
	.print-content {
		width: 100%;
	}
	section.print-sidebar {
		display: none;
	}
	.print-content .maincontent {
		height: auto !important;
		width: 100%;
		overflow-y: visible !important;
		display: block;
	}



    /* document css */

    .document .left-sidebar,
    .content .gam,
    section.preview {
        display: none !important;
    }

    .document .content{
        width: 100% !important;
        margin: -150px 0 0 50px !important;
    }

    .print-content a * {
        color: #0088cc !important;
    }

    .print-content .in-line-doc {
        background-color: #cbcbcb !important;
        border: 1px solid #cbcbcb;
    }

    .print-content .gam-graybox, .print-content .block-ele {
        page-break-inside: avoid;
    }

    .print-content table { 
        page-break-inside:auto 
    }

    .print-content tr { 
        page-break-inside:avoid; 
        page-break-after:auto;
    }
	
	.print-content .maincontent .footnotes ul li .fn-no{
		color:#fff;
		border:1px solid #333;
		padding: 0 5px;
		margin: 0 6px 0 0;
	}
    
    a[href]:after {
        content: "";
    }
}


