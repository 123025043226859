/****** EY Online Style Guide *****/
/* Standard Table Styles */

table{
	border:1px solid #c0c0c0;
	width:100%;
    font-size:12px;
}

tr > *{
	padding:12px;
}

tr:nth-child(odd){
    background-color: #fff;
}

tr:nth-child(even){
    background-color: #f2f2f2;
}

th{
    background-color:#c0c0c0;
    border:none;
}

th:first-child,
td:first-child{
	width: 48px;
}


/***** Nested Table Styles *****/
/* Parent Table Styles */

table.parentTbl table:nth-child(odd){
	background-color: #f2f2f2;
}

table.parentTbl table:nth-child(even){
	background-color: transparent;
}

table.parentTbl tr td:first-child{
	background-color: #fff;
}

table.parentTbl tr{
	border-bottom:1px solid #c0c0c0;
}

table.parentTbl tr:last-child{
	border-bottom:none;
}

table tr.parentRow{
	border-bottom:none;
}

tr:nth-child(odd).parentRow,
tr:nth-child(even).parentRow{
	background-color: transparent;
}

.table-responsive .fa-plus,
.table-responsive .fa-minus {
    font-size:12px;
    color:#336699;
}

