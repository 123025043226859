/* .dropdown {
    display:inline-block;
}

.dropdown > button,
.dropup > button {
    padding:10px;
    background-color:#fff;
    border:1px solid #c0c0c0;
    line-height:16px;
    outline:none !important;
    color:#666;
}


.dropdown > button > [class*='fa-chevron'],
.dropdown > button > [class*='fa-angle'],
.dropup > button > [class*='fa-chevron'],
.dropup > button > [class*='fa-angle'] {
    float:right;
    margin-top:2px;
    margin-right:0;
    margin-left:10px;
    color:#999;
}

.dropdown > .dropdown-menu,
.dropup > .dropdown-menu {
    border-radius:0;
    padding: 0 0;
}

.dropdown > .dropdown-menu {
    margin-top:0;
    min-width:100%;
}

.dropdown-menu>li {
    border-bottom:thin solid #c0c0c0;
}

.dropdown-menu>li:last-of-type {
    border-bottom:0;
}


.dropdown-menu>li.disbaled>a {
    color:#f4f4f4;
}

.dropdown-menu>li>a {
    display: block;
    padding: 10px;
    clear: both;
    font-weight: 400;
    line-height: 16px;
    white-space: nowrap;
    color:#666;
}

.dropdown-menu>li>a:hover {
    background-color:#f0f0f0;
    color:#666;
} */