/*START STYLES FOR INPUT FIELDS*/
.form-group.validate {
    position:relative;
}

.form-group > label {
    font-weight:normal;
}

.form-group > label > .fa-info-circle {
    color:#336699;
    float:right;
    margin-top:4px;
}

input:not([type="radio"]):not([type="checkbox"]){
    /* display:block;
    padding: 5px 10px;
    border: 1px solid #c0c0c0;
    color: #666;
    border-radius:0;
    width:100%;
    line-height:26px; */
}

input::-ms-clear {
    /* display: none; */
}

input:focus {
    /* border:1px solid #ffe600 !important;
    outline:0; */
}

/*INPUT PLACEHOLDER*/
input::-webkit-input-placeholder{  
   /* color: #aeaeae;   */
}
input::-moz-placeholder{  
   /* color: #aeaeae;   */
}
input:-ms-input-placeholder{  
   /* color: #aeaeae !important;   */
}


/*REQUIRED FIELD*/
input.required,
input.required:focus {
    /* border:1px solid #f04c3e !important; */
}

.required-errmsg{
    display: block;
    position: relative;
    top: -2px;
    left: 0;
    margin: 0 0 0 0;
    padding: 10px 10px 10px 34px;
    width: 100%;
    z-index: 10;
    border: 1px solid #f04c3e;
    background-color: #fbd2ce;
}

.required-errmsg > span.fa-exclamation-triangle{
    position: relative;
    top: 0;
    left: -24px;
    margin-right: -18px;
    color:#f04c3e;
}


/*INPUT REQUIRED FIELDS WITH NOTATIONS*/
label.required:before {
    /* content:"*";
    position:relative;
    font-weight:normal;
    color:#f04c3e;
    padding-right:3px;
    margin-left:-10px; */

}
input:required:invalid + span.status::after, 
input:focus:invalid + span.status::after {
    /* position:absolute;
    content: "X";
    color: #ce0000;
    right:3px;
    bottom:4px;
    font-weight:bold;
    font-size:32px;
    font-style:italic; */
}
input:required:valid + span.status::after, 
input:optional:valid + span.status::after,
input:optional:invalid + span.status::after {
    /* position:absolute;
    content: "\2713";
    color: limegreen;
    right:-4px;
    bottom:4px;
    font-weight:bold;
    font-size:32px; */
}

/*SEARCH INPUT RELATED STYLES*/
input[type="search"] {
    /* padding-right:32px !important; */
}

.search-container {
    /* position:relative; */
}

.search-container > input[type="search"] + span {
    /* position:absolute;
    top:10px;
    right:10px;
    color:#999; */
}

/*CHECKBOX/RADIO BUTTONS*/
.checkbox, .radio {
    /* min-width:150px;
    font-weight:normal;
    padding-left:24px; */
}


input[type=radio], 
input[type=checkbox] {
    /* width:20px;
    height:20px; */
}

input[type=radio] + label, 
input[type=checkbox] + label{
    /* position:relative;
    display:inline-block;
    font-weight:normal;
    margin-left:8px;
    top:-4px; */
}


/*PAGING*/
.form-paging {
    position:relative;
    padding-left:15px;
}

.form-paging > a,  .form-paging > a:hover{
    display:inline-block;
    line-height:14px;
    padding:15px;
    text-decoration:none;
}

.form-paging > a:last-of-type{
    margin-right:15px;
}

.form-paging > a.active {
    border:thin solid #c0c0c0;
    color:#c0c0c0;
    padding:14px;
}

.form-paging > a:nth-of-type(-n+2),
.form-paging > a:nth-last-of-type(-n+2) {
    padding:0;
    margin-right:15px;
    color:#333;
}

.form-paging > a:nth-last-of-type(2) {
    margin-left:15px;
}

.form-paging > .dropdown,
.form-paging > .dropup {
    position:relative;
    display:inline-block;
    margin-left:20px;
    margin-right:10px;
}

.form-paging > .dropdown > button,
.form-paging > .dropup > button {
    min-width:75px;
    text-align:left;
}

.form-paging > .dropdown > .dropdown-menu,
.form-paging > .dropup > .dropdown-menu {
    min-width:75px;
}
