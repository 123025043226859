/*FONT AWESOME OVERRIDE*/
.fa {
    font-size:18px;
}

svg{
	width:100%;
	height:100%;
    text-indent: 0;
}

.ey-icon {
	width: 16px;
	height: 16px;
	overflow: hidden;
    display: inline-block;
    line-height: normal;
    vertical-align: top;
}


