main{
	width:100%;
	height:100%;
	position:relative;
}


.left-sidebar,
.right-sidebar {
	height: 100%;
	width:310px;
	color:#ffffff;
	background-color:#44494F;
	vertical-align:top;
	position:fixed;
	top:80px;
	display:table-cell;
}

.left-sidebar{
	left: 0px;
}

.right-sidebar{
	right: -310px;
}

.push-left{
	margin-left:310px;
}

.left-sidebar .black-strip{
	background-color: #24282D;
	width: 100%;
	color: #fff;
	display: inline-block;
	margin: 12px 0px 10px 0px;
	padding: 5px 8px 4px 8px;
	font-size: 12px;
	font-weight: 600;
}

.left-sidebar .toggle-icon{
	float: right;
	width: 20px;
	background: none;
}

footer{
	background-color: #bec1c7;
	position: fixed; 
    bottom: 0;
    left: 0;
    right: 0;
	padding: 10px 10px;
    height: 50px;
	width:100%;
	display:none;

}

footer span{
	display: table-cell;
	text-align:center;
}

