/*Button Styles*/
/*START BOOTSTRAP RESET STYLES*/
.btn {
   padding: 12px 22px;
   color: #333;
   border-radius:0;
   border:0;
   box-sizing: border-box;
   box-shadow:none;
   line-height:14px;
}

.btn-ico {
   box-shadow:none;
   padding: 12px 0;
   border-radius:0;
   border:none;
   width:38px;
   line-height:14px;
}

.btn-ctr {
   box-shadow:none;
   padding: 8px 22px;
   color: #333;
   border-radius:0;
   border:1px solid transparent;
}

.btn:focus,.btn:active {
    box-shadow:none;
    outline:0;
}

/*PRIMARY BUTTONS*/
.btn.btn-primary, .btn-ico.btn-primary,
.btn-ctr.btn-primary {
   background-color: #ffe600;
   box-shadow:inset -1px -1px 0px 1px #c0c0c0;
   color:#333;
   border:none;
}

.btn.btn-primary:hover,
.btn-ico.btn-primary:hover,
.btn-ctr.btn-primary:hover {
   box-shadow:inset 0px 0px 0px 0px transparent;
   color:#333;
}

.btn.btn-primary:active,
.btn-ico.btn-primary:active,
.btn-ctr.btn-primary:active {
   box-shadow:inset 0px 0px 0px 1px #c0c0c0;
   outline:0;
}

/*SECONDARY BUTTONS*/
.btn.btn-secondary,
.btn-ico.btn-secondary {
   background-color: #666;
   box-shadow:inset -1px -1px 0px 1px #333;
   color:#fff;
}


.btn.btn-secondary:hover,
.btn-ico.btn-secondary:hover {
   box-shadow:none;
}

.btn.btn-secondary:active,
.btn-ico.btn-secondary:active {
   box-shadow:inset 0px 0px 1px 1px #333;
   border:none;
   outline:0;
}

/*UTILITY BUTTONS*/
.btn.btn-util, .btn-ico.btn-util {
    background-color: #fff;
    box-shadow:inset -1px -1px 1px 1px #666;
    color:#333;   
}

.btn.btn-util:hover, .btn-ico.btn-util:hover {
   box-shadow:inset 0px 0px 0px 1px #f0f0f0;
}

.btn.btn-util:active, .btn-ico.btn-util:active{
   box-shadow: 0px 0px 0px 1px #666;
   outline:0;
}

/*BUTTON LINKS*/
.btn.btn-link, .btn.btn-link:hover {
    box-shadow:none;
    text-decoration:none;
    color:#336699;
}

.btn.btn-link:active,.btn.btn-link.active{
    box-shadow:inset 0px 0px 0px 1px #666;
    outline:0;
    text-decoration:none;
}

.btn.btn-link>span {
    margin-right:8px;
}

/*COUNTER*/
.btn-ctr > .ctr {
    display: inline-block;
    border: 1px solid #333;
    text-align: center;
    line-height: 18px;
    width: 20px;
    height: 20px;
}

.btn-ctr > span,
.btn > span {
    margin-right: 8px;
}

.btn.selected,
.btn.selected:hover{
   background-color: #44494F;
   color:#fff;
}

/* Disabled btn*/
.btn.disabled {
    background-color: #fff;
    color: #dcdcdc;
    box-shadow: 0px 0px 0px 1px #dcdcdc;
}

.btn-link.disabled {
    box-shadow: none;
}


/*button icon font size*/
.btn > .fa,
.btn-ico > .fa {
    font-size:14px;
}
