/* twitter bootstrap */
@import "../libs/bootstrap.min.css";

/* font awesome icons */
@import "../libs/font-awesome-4.5.0/css/font-awesome.min.css";

/*Fonts EY theme */
@import "fonts.css";

/*Baseline EY theme */
@import "baseline/baseline.css";

/*Box Shadow EY theme */
@import "boxshadow/boxshadow.css";

/*Colors EY theme */
@import "colors/colors.css";
 
/* navigation */
@import "navigation/navigation.css";
@import "navigation/tabs.css";

/* layout */
@import "layout/layouts.css";

/* Components */
@import "components/modal.css";
@import "components/popoveractions.css";
@import "components/tooltips.css";
@import "components/messages.css";

/*Page Elements */
@import "forms/forms.css";
@import "tables/tables.css";
@import "forms/dropdown.css";
@import "icons/icons.css";
@import "buttons/buttons.css";
@import "buttons/toggle_switches.css";

/* Styles specific to printing */
@import "print/print.css";

@import 'eyMediaQueries/eyMediaQueries.css';