/***** Primary Navigation *****/
.primary-cont {
    background-color:#333;
    padding:0 0;
}

.primary{
	font-size:16px;
	color:#c0c0c0;
	height:80px;
}

.applicationName h2{
  font-family: 'NotoSans', 'Arial Unicode MS Regular', sans-serif; 
	font-size:14px;
  font-weight: normal;
  line-height: normal;
	height:40px;
	border-bottom:1px solid #666;
  padding:10px 14px 10px !important;
  color:#c0c0c0;
}

.nav-links, 
.primary .logo {
    display:inline-block;
}

.primary > .logo img {
    width:48px;
    height:54px;
}

.primary .logo,
.applicationName .logo{
	width: 37px;
	height:44px;
	margin: 0 60px 0 0;
	padding:0;
	background-color:transparent;
}

.primary > .logo {
    width:60px;
    line-height:75px;
}

.primary .logo button{
  padding:0;
  margin:0;
  border-bottom:none;
  background-color: transparent;
}

.nav-links > a,
.nav-links > .dropdown > a{
	color:#c0c0c0;
	display: inline-block;
	float: none;
  border-bottom:5px solid #333;
  line-height:47px;
}

.nav-links a,
.nav-links a:link,
.nav-links a:visited{
	padding:14px 20px;
	color:#c0c0c0;
}

.nav-links > a:hover,
.nav-links > .dropdown.open > a,
.nav-links > .dropdown > a:hover{
	padding:14px 20px;
	border-bottom:5px solid #ffe600;
	text-decoration: none;
	color:#c0c0c0;	
  background-color:#000;
}

.nav-links a > .caret,
.nav-links a > .fa{
    margin-left:10px;
}

.nav-links .dropdown {
    display:inline-block;
}

.nav-links .dropdown-menu>li>a {
    color:#c0c0c0;
    padding:17px 20px 16px;
    background-color:#000;
}
.nav-links .dropdown-menu>li>a:hover {
    background-color:#666;
    color:#c0c0c0;
}

.navbar-toggle .icon-bar {
  background-color: #f4f4f4;
  display: block;
  width: 22px;
  height: 2px;
  border-radius: 1px;
  margin:3px 0;
}

/* .collapse.in, .navbar-collapse.collapse {
    display:inline-block !important;
} */

.primary .navbar-collapse.collapse.in+.navbar-toggle.collapsed {
    background-color: #000;
}