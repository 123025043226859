/****** RTF Baseline CSS **********/
html{
	font-size: 100%;
	height:100%;
}

body{
	width:100%;
	height:100%;
	margin:0 auto;
	padding:0;
}

a:link, a:hover, a:visited, a:active{
	color:#336699;
	text-decoration:none;
}

a:hover{
    text-decoration: underline;
}


button, a{
	border:none;
	outline: none;
}

/*
*:focus,button:focus, *[role="button"]:focus,*[role="link"]:focus{
	 outline:thin dotted; 
}

a:not(.btn):hover, *[role="button"], *[role="link"] {
    text-decoration:underline; 
}
*/

h1, h2{
	color: #000;
}

h1, h2, h3, h4, h5, h6{
	margin:0;
	padding:0;
}

:not(pre) > span{
	display:inline-block;
}

ul{
  -webkit-padding-start: 0;
}

ul li{
  list-style: none;
  margin: 0;
  padding:0;
}


footer{
	background-color: #bec1c7;
	position: fixed; 
    bottom: 0;
    left: 0;
    right: 0;
	padding: 10px 10px;
    height: 50px;
	width:100%;
	display:none;

}

footer span{
	display: table-cell;
	text-align:center;
}
