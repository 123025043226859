@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import '../node_modules/quill/dist/quill.core.css';
@import '../node_modules/quill/dist/quill.snow.css';
@import '../node_modules/ngx-bootstrap/datepicker/bs-datepicker.css';
@import '../node_modules/@ag-grid-community/styles/ag-grid.css';
@import '../node_modules/@ag-grid-community/styles/ag-theme-quartz.css';

@import '../node_modules/primeicons/primeicons.css';
@import '../node_modules/primeng/resources/themes/nova/theme.css';
@import '../node_modules/primeng/resources/primeng.min.css';

/* You can add global styles to this file, and also import other style files */
.mt-1 {
  margin-top: 5px;
}

.mb-1 {
  margin-bottom: 5px;
}


.ag-icon-grip {
  background-image: url(/assets/icons/vertical-split.svg) !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 1.2rem;
  height: 3rem;
}

.ag-icon-grip::before {
  display: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ml-1 {
  margin-left: 5px;
}

.mr-1 {
  margin-right: 5px;
}

body {
  zoom: 100%;
  background: #f6f6fa;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: #FFF;
  width: 12px;
}

::-webkit-scrollbar-thumb {
  background: #6E6E6E;
  border-radius: 15px;
  padding: 5px;
}

::-webkit-scrollbar-button:single-button {
  background-color: #FFF;
  display: block;
  border-style: solid;
  height: 10px;
  width: 10px;
}

/* Up */
::-webkit-scrollbar-button:single-button:vertical:decrement {
  border-width: 0 5px 5px 5px;
  border-color: transparent transparent #6E6E6E transparent;
}

/* Down */
::-webkit-scrollbar-button:single-button:vertical:increment {
  border-width: 5px 5px 0 5px;
  border-color: #6E6E6E transparent transparent transparent;
}


/* Left */
::-webkit-scrollbar-button:single-button:horizontal:decrement {
  border-width: 5px 5px 5px 0;
  border-color: transparent #6E6E6E transparent transparent;
}

/* Right */
::-webkit-scrollbar-button:single-button:horizontal:increment {
  border-width: 5px 0 5px 5px;
  border-color: transparent transparent transparent #6E6E6E;
}


@media only screen and (min-width: 1440px) {

  /* styles for browsers larger than 1440px; */
  .container {
    width: 1300px;
  }

  .modal-dialog {
    width: 1076px;
    margin: 30px auto;
  }

  .modal .modal-header {
    height: 50px;
  }

  .navbar {
    height: auto;
  }

  .thumbnail {
    width: 300px;
    height: 300px;
    margin: 10px;
  }
}

/*Header Styles*/
/*START BOOTSTRAP RESET STYLES*/
h1 {
  font-family: 'EYInterstate', sans-serif;
  font-weight: bold;
  font-size: 24px;
  letter-spacing: 0px;
  line-height: 30px;
  color: #2e2e38;
}

h2 {
  font-family: 'EYInterstate', sans-serif;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 20px;
  color: #2e2e38;
}

h3 {
  font-family: 'EYInterstate', sans-serif;
  font-weight: bold;
  font-size: 24px;
  letter-spacing: 0px;
  line-height: 30px;
  color: #2e2e38;
}

h4 {
  font-family: 'EYInterstate', sans-serif;
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 20px;
  color: #2e2e38;
}

h5 {
  font-family: 'EYInterstate', sans-serif;
  font-size: 20px;
  letter-spacing: 0px;
  line-height: 28px;
  color: #2e2e38;
}

h6 {
  font-family: 'EYInterstate', sans-serif;
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 20px;
  color: #2e2e38;
}

/*Body Styles*/
/*START BOOTSTRAP RESET STYLES*/
.body1 {
  font-family: 'EYInterstate', sans-serif;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 20px;
  color: #646464;
}

.body2 {
  font-family: 'EYInterstate', sans-serif;
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 20px;
  color: #646464;
}

.body3 {
  font-family: 'EYInterstate', sans-serif;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 20px;
  color: #646464;
}

.body4 {
  font-family: 'EYInterstate', sans-serif;
  font-size: 12px;
  letter-spacing: 0px;
  line-height: 16px;
  color: #646464;
}

.body5 {
  font-family: 'EYInterstate', sans-serif;
  font-size: 10px;
  letter-spacing: 0px;
  line-height: 14px;
  color: #999999;
}

/*Button Styles*/
/*START BOOTSTRAP RESET STYLES*/

/*PRIMARY BUTTONS*/

.btn.btn-primary,
.btn-ico.btn-primary,
.btn-ctr.btn-primary {
  background-color: #2e2e38;
  color: #ffffff;
  border: none;
  font-family: 'EYInterstate', sans-serif;
  font-weight: bold;
  font-size: 14px;
  box-shadow: none;
  height: 40px;
}

.btn.btn-primary:hover,
.btn-ico.btn-primary:hover,
.btn-ctr.btn-primary:hover {
  color: #ffffff;
}

.btn.btn-primary:active,
.btn-ico.btn-primary:active,
.btn-ctr.btn-primary:active {
  outline: 0;
}

/*SECONDARY BUTTONS*/
.btn.btn-secondary,
.btn-ico.btn-secondary {
  background-color: #ffffff;
  color: #2e2e38;
  font-family: 'EYInterstate', sans-serif;
  font-weight: bold;
  font-size: 14px;
  box-shadow: none;
  border-width: 1px;
  border: 1px solid #2e2e38;
}

.btn.btn-secondary:hover,
.btn-ico.btn-secondary:hover {
  box-shadow: none;
  color: #2e2e38;
}

.btn.btn-secondary:active,
.btn-ico.btn-secondary:active {
  border: none;
  outline: 0;
}

.btnLarge {
  min-width: 180px;
}

.btnMedium {
  min-width: 140px;
}

.btnSmall {
  min-width: 100px;
}

/* Disabled btn*/
.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
  background-color: #eaeaea;
  font-family: 'EYInterstate', sans-serif;
  font-weight: bold;
  color: #999999;
  box-shadow: 0px 0px 0px 1px #dcdcdc;
  border: none;
}

.btn-link.disabled {
  box-shadow: none;
}

/*LINKS*/

a {
  font-weight: bold;
}

a:link {
  font-family: 'EYInterstate', sans-serif;
  font-weight: bold;
  font-size: 14px;
  color: #155cb4;
  letter-spacing: 0px;
  line-height: 20px;
  text-decoration: none;
}

/* a:hover {
  text-decoration: underline;
} */

a:link,
a:hover,
a:visited,
a:active {
  color: #155cb4;
}

/*Form controls*/
.form-group>label {
  font-family: 'EYInterstate', sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #646464;
  font-weight: bold;
}

select {
  border-radius: 0 !important;
}

/* textarea {
  border-radius: 0 !important;
} */

.form-group>label .required-errmsg {
  display: none;
  position: relative;
  font-family: 'EYInterstate', sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  background-color: white;
  color: #e0362c;
}

.required-labelmsg {
  color: #e0362c !important;
}

.required-labelmsg>small {
  color: #e0362c !important;
  display: inline-flex;
}

.requiredsmall {
  /* color: #FFFFFF !important; */
  display: none;
}

select.required,
select.required:focus {
  border: 1px solid #f04c3e !important;
}

textarea.required,
textarea.required:focus {
  border: 1px solid #f04c3e !important;
}

.required-errmsg {
  display: none;
  position: relative;
  top: 0px;
  left: 0;
  margin: 0 0 0 0;
  padding: 10px 10px 10px 10px;
  width: 100%;
  z-index: 10;
  border: 0px;
  background-color: white;
  color: #e0362c;
}

::ng-deep .cdk-overlay-container {
  z-index: 1 !important;
}

.form-group.has-error .required-errmsg {
  display: block;
}

/*Modal*/
.modal .modal-header {
  background-color: #2e2e38;
  padding: 15px 20px 20px 20px;
}

.modal .modal-header .modal-title {
  color: #ffffff;
}

.modal .modal-body {
  background-color: #ffffff;
  padding: 15px 20px 20px 20px;
  outline: 1px solid #c4c4cd;
  outline-offset: -1px;
}

.modal .modal-footer {
  background-color: #ffffff;
  padding: 15px 20px 20px 20px;
}

.modal .modal-footer .btn {
  margin-left: 0px;
  margin-right: 20px;
}

/*Alert boxes*/
.alert {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 30px;
  font-family: 'EYInterstate', sans-serif;
  font-size: 14px;
  font-weight: normal;
  color: #646464;
}

.alert-danger {
  background-color: #fff6f5;
  border: solid #e78680 1px;
}

.alert-success {
  background-color: #e8f6ed;
  border: solid #2db757 1px;
}

.alert-warning {
  background-color: #F3F3F5;
  color: #23232F;
}

.close {
  position: relative;
  top: -6px;
  right: 0px;
  float: right;
  font-size: 32px;
  line-height: 1;
  font-weight: 700;
  opacity: 1;
  color: #646464;
}

.alert-dismissable .close,
.alert-dismissible .close {
  position: relative;
  top: -6px;
  right: 0px;
  color: inherit;
}

/* Panel Styles */

.panel {
  border-radius: 0px !important;
  box-shadow: none !important;
}

.panel-heading {
  color: #646464;
  background-color: #c4c4ce;
  height: 50px;
  /* opacity: 0.5; */
  padding: 0;
}

.panel-body {
  padding: 0px;
}

.panel-collapse {
  width: 100%;
}

/*Tables*/
th {
  background-color: #2e2e38;
  border: none;
  height: 50px;
  font-family: 'EYInterstate', sans-serif;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 20px;
  color: #ffffff;
}

td,
th {
  padding: 0 12px;
  height: 50px;
}

.table {
  margin-bottom: 0px;
}

.tr-off {
  opacity: 0.5;
}

tr:nth-child(even) {
  background-color: #ffffff;
}

td {
  border: solid 1px #c4c4cd;
}

/* tr{
  height: 50px;
} */

/*Switches*/
/*.switch-label {
  border: 1px solid #155CB4;
  border-radius: 14px;
  padding: 0 0;
}*/

.switch-cont:before,
.switch-cont:after {
  font-weight: normal;
}

.switch-cont:before {
  content: attr(yes);
  text-align: left;
}

.switch-cont.blue:before {
  background-color: #336699;
  color: #fff;
}

.switch-cont:after {
  content: attr(no);
}

.switch-btn {
  width: 20px;
  height: 28px;
}

/*Navigation bar*/

.navbar {
  background-color: #2e2e38;
  margin-bottom: 0px;
}

.navbar-nav {
  margin-top: 10px;
  float: right;
  background-color: #2e2e38;
}

.navbar-nav>li>a {
  color: #ffffff !important;
  font-size: 16px;
  font-weight: normal;
  font-family: 'EYInterstate', sans-serif;
  border-bottom: #2e2e38 4px solid;
}

.navbar-nav>li.active>a {
  color: #ffe600 !important;
  font-size: 16px;
  font-weight: bold;
  font-family: 'EYInterstate', sans-serif;
  border-bottom: #ffe600 4px solid;
}

.navbar-nav>li>a:focus {
  color: #ffe600 !important;
  font-size: 16px;
  font-weight: bold;
  font-family: 'EYInterstate', sans-serif;
  border-bottom: #ffe600 4px solid;
}

.navbar-nav>li>a:hover {
  color: #ffe600 !important;
  font-size: 16px;
  font-family: 'EYInterstate', sans-serif;
  border-bottom: #ffe600 4px solid;
}

.navbar-inverse .navbar-nav>.active>a,
.navbar-inverse .navbar-nav>.active>a:focus,
.navbar-inverse .navbar-nav>.active>a:hover {
  background-color: #2e2e38;
}

.navbar-header img {
  width: 33px;
  height: 34px;
}

.navbar-brand {
  color: #ffffff !important;
  font-weight: normal !important;
  font-size: 24px !important;
  margin-top: 10px;
}

.navbar-logout {
  margin-right: -40px;
  margin-top: 22px;
  display: block;
}

.maticon-a {
  cursor: pointer;
  color: #ffffff;
  padding-right: 5px;
}

.users-list .ag-header-cell-comp-wrapper {
  font-weight: bold !important;
}

.users-list .ag-overlay {
  z-index: 0;
}

.users-list .ag-center-cols-container, .users-list .ag-center-cols-viewport {
  min-height: 71px !important;
}

@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
  .maticon-a {
    cursor: pointer;
    color: #ffffff;
    padding-right: 5px;
    margin-top: -3px;
  }
}

@media screen and (max-width: 1024px) {
  .navbar-logout {
    margin-right: 0px;
  }
}

@media screen and (max-width: calc(75rem - 1px)) {
  .motif-text-link[color=primary-alt]:not([disabled=true]):hover:before {
    border-bottom: none;
  }
}

@media screen and (max-width: 767px) {
  .navbar-logout {
    display: none;
  }
}

/* Tooltip styles */

.tooltip-inner {
  font-family: 'EYInterstate', sans-serif;
  font-size: 10px;
  letter-spacing: 0px;
  color: #ffffff !important;
  line-height: 14px;
  text-align: left;
  border: 1px solid #c4c4cd;
  background-color: #333333;
}

.tooltip-center {
  text-align: center;
}

.ag-header-cell-resize {
  z-index: unset !important;
}

motif-tooltip span[aria-details="centered-aligned-tooltip"],
motif-tooltip span[aria-details="centered-aligned-tooltip-arrow"] {
  text-align: center;
}

motif-tooltip span[aria-details="ql-master-tooltip"] {
  max-width: 230px !important;
}

.motif-checkbox-custom {
  border-radius: 2px !important;
}

.motif-checkbox-checked-icon svg {
  width: 14px !important;
  height: 14px !important;
}

motif-expansion-panel-header .motif-checkbox-checked-icon svg {
  width: 12px !important;
  height: 12px !important;
}

.motif-checkbox-checked-icon .motif-icon-content {
  align-items: center;
  justify-content: center;
}

.motif-tooltip-bottom:has(motif-tooltip span[aria-details="centered-aligned-tooltip-arrow"]):before {
  left: 58px !important;
}

/* Error Message */

.error-float-top {
  position: fixed;
  z-index: 1000;
  width: 100%;
  top: 0;
  opacity: 0.9;
}

.thumbnail {
  width: 300px;
  height: 250px;
  margin: 10px;
}

.disabledicon {
  opacity: 0.3;
}

/* Stepper Style */

.mat-stepper-vertical {
  /* background: linear-gradient(to bottom,#336699 35%,#00A3AE 100%); */
  background: transparent;
}

.mat-step-header .mat-step-label {
  color: #c4c4cd;
}

.mat-step-header .mat-step-label.mat-step-label-active {
  color: #c4c4cd;
}

.mat-step-header .mat-step-label-selected .mat-step-text-label {
  color: #ffffff !important;
  font-weight: 600 !important;
}

.mat-step-label-selected {
  color: #ffffff !important;
  font-weight: 600 !important;
}

.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #ffffff;
  color: rgba(0, 0, 0, 0.87);
}

.mat-step-header .mat-step-icon {
  background-color: #ffffff;
  color: black;
}

/* .material-icons {
  color: black;
} */

.mat-stepper-vertical-line::before {
  border-left-color: #ffffff;
}

.mat-step-header .mat-step-icon-selected .ng-star-inserted {
  background: black;
  border-radius: 100%;
  width: 18px;
  height: 18px;
  display: inherit;
  color: transparent;
}

.mat-step-header .mat-step-icon-state-number .mat-step-icon-content>span {
  color: transparent;
}

.mat-vertical-content {
  padding: 0px !important;
}

.completed {
  color: #ffffff !important;
}

.notcompleted {
  color: #c4c4cd !important;
}

/* Inner stepper changes */

.innerstepper .mat-stepper-vertical-line::before {
  display: none;
}

.innerstepper .mat-step-header {
  box-sizing: inherit !important;
  padding: 20px;
  padding-left: 35px;
}

.innerstepper .mat-stepper-vertical {
  background: transparent;
}

.innerstepper .mat-step-header .mat-step-icon {
  display: none;
}

.innerstepper .mat-step-header .mat-step-label {
  color: #c4c4cd;
  font-family: 'EYInterstate', sans-serif;
  font-size: 12px;
  letter-spacing: 0px;
  line-height: 24px;
}

.innerstepper .mat-step-label-selected {
  color: #ffffff !important;
  font-weight: 900 !important;
}

.pointer {
  cursor: pointer;
}

/*
 *  Scrollbar STYLE 1
 */

.scrollbar::-webkit-scrollbar {
  width: 12px;
}

.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #c4c4cd;
  height: 100px;
}

/* Editor CSS */

.invalideditor {
  border: 1px solid red;
}

.ql-editor.ql-blank::before {
  font-style: inherit;
}

/* Editor CSS - End */

.disclaimer {
  color: #C70117;
  font-family: 'EYInterstate', sans-serif;
  font-size: 1rem;
  margin-bottom: 0.5rem;
  font-weight: 300;
}

.actionmaticon {
  cursor: pointer;
  color: #646464;
  font-size: 20px;
}

.autosave>span::after {
  content: 'Changes auto saved';
}

.unsave>span::after {
  content: 'Changes not saved yet';
}

/* Show More - Show less - Start */
/* .showmorecontainer {
  height: 60px;
  overflow: hidden;
}

.show {
  overflow: visible;
  height: auto;
} */

.showmorebtn {
  cursor: pointer;
}

/* Show More - Show less -End */

/* AG Grid  styles - start */

.ag-theme-quartz .ag-header {
  background-color: #F3F3F5;
  font-family: 'EYInterstate', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #000000;
  /* --ag-grid-size: 10px;
  --ag-list-item-height: 40px;
  --ag-icon-font-family: 'agGridMaterial', sans-serif; */

}

.ag-theme-quartz .ag-root-wrapper {
  border-radius: 0px;
  border: none;
}

.ag-theme-quartz .ag-root-wrapper-body {
  border-radius: var(--ag-wrapper-border-radius);
  border: var(--ag-borders) var(--ag-border-color);
}

.ag-theme-quartz .ag-pinned-left-header {
  border: none;
}

.ag-theme-quartz .ag-checkbox-input-wrapper {
  line-height: 16px;
  font-size: 16px;
}

.ag-theme-quartz .ag-checkbox-input-wrapper::after,
.ag-theme-quartz .ag-checkbox-input-wrapper.ag-checked::after,
.ag-theme-quartz .ag-checkbox-input-wrapper.ag-indeterminate::after {
  color: #7F7F91;
}

/* AG Grid  styles - End */

/* Side bar  styles - start */

#vbutton {
  /* margin-top:25px; */
  margin-left: -80px;
  width: 200px;
  height: 30px;
  transform: rotate(7deg);
  -ms-transform: rotate(90deg);
  /* IE 9 */
  -moz-transform: rotate(90deg);
  /* Firefox */
  -webkit-transform: rotate(90deg);
  /* Safari and Chrome */
  -o-transform: rotate(90deg);
  /* Opera */
  background-color: #2e2e38;
  font-family: 'EYInterstate', sans-serif;
  font-weight: bold;
  font-size: 14px;
  color: #ffffff;
  position: fixed;
  top: 40vh;
  opacity: 0.9;
}

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  background-color: #2e2e38;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  display: block;
  transition: 0.3s;
  font-family: 'EYInterstate', sans-serif;
  font-weight: bold;
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
}

.sidenav span {
  padding: 8px 8px 8px 8px;
  margin: 20px;
  display: block;
  transition: 0.3s;
  font-family: 'EYInterstate', sans-serif;
  font-weight: bold;
  font-size: 16px;
  color: #ffffff;
  border-bottom: 2px solid #ffe600;
}

.sidenav a:hover {
  color: #ffe600;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 20px;
  font-size: 36px;
  margin-left: 50px;
  cursor: pointer;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }

  .sidenav a {
    font-size: 18px;
  }
}

/* Side bar  styles - Ends */

/* PrimeNg  styles - Ends */

body .ui-corner-all {
  border-radius: 0px !important;
}

body .ui-multiselect {
  width: 100%;
  border: 0;
}

body .ui-multiselect .ui-multiselect-label {
  padding-top: 0.55555em;
}

/*
.ui-multiselect .ui-multiselect-trigger {
  height: 95%;
  top: 0.5px;
} */

.ui-multiselect .ui-multiselect-label-container {
  height: 30px;
}

.invalidmultiselect {
  border: 1px solid red;
}

.validmultiselect {
  border: 1px solid #cdcdc4;
}

div.ui-listbox-list-wrapper ul.ui-listbox-list li.ui-listbox-item.ui-corner-all {
  align-items: center;
  font-size: 13px;
  white-space: nowrap;
  overflow-x: hidden;
}

/* PrimeNg  styles -  styles - Ends */

.ag-cell-wrapper {
  align-items: flex-start;
}

.questionlabel>ul li {
  list-style: disc;
  margin-left: 20px;
}

.gridquestionlabel>div p {
  padding: 0;
  margin: 0;
}

.gridquestionlabel>div ul li {
  list-style: disc;
  margin-left: 20px;
}

.overflow-hidden {
  overflow-y: hidden;
}

.addquestion {
  min-width: 0;
  width: 80.286rem;
  max-width: 80.286rem !important;
  max-height: 704px !important;
}

.adduser {
  width: 61.92857rem;
  min-height: 23.142857rem;
  max-width: 61.92857rem !important;
  /* max-height: 23.142857rem !important; */
}

.copyfrom {
  width: 45.642857142rem;
  min-height: 28.785714rem;
  max-width: 38.642857rem !important;
  max-height: 28.785714rem !important;
}

.privacypolicy {
  width: 50.642857142rem;
  max-width: 50.642857rem !important;
  max-height: 95%!important;
}


.reset {
  width: 39.642857rem;
  min-height: 20.714285714rem;
  max-width: 45.642857142rem !important;
  max-height: 20.714285714rem !important;
}

.functionalarea {
  min-height: 16.714286rem !important;
}

.resetproject {
  width: 63.9285714rem;
  max-width: 63.9285714rem !important;
  max-height: 100% !important;
}

.addNotesPopUp {
  max-width: 95vw !important;
  max-height: 100vh !important;
}

.addNotesPopUp motif-modal-content {
  height: 385px !important;
  max-height: 385px !important;
}

.ownerPopUp {
  max-width: 1000px !important;
}

.deletequestion {
  width: 45.64285714rem;
  /* min-height: 15.5rem; */
  max-width: 45.64285714rem !important;
  /* max-height: 15.5rem !important;
  max-height: 23.142857srem !important; */
}

:root motif-modal-title {
  margin: 1rem 1.7142857143rem !important;
  padding: 0 0 0.5142857143rem !important;
}

/* :root .motif-modal-actions {
  margin: 1rem 1.7142857143rem !important;
} */

:root motif-modal-title .motif-icon-button {
  display: flex !important;
  padding: 14px !important;
}

@media screen and (max-width: 64em) {

  .resetproject,
  .adduser,
  .addquestion {
    width: 55rem;
    max-height: 100% !important;
  }

  .unsaved-modal {
    width: 100% !important;
  }
}

.motif-form-field .motif-input:not([disabled]), .motif-form-field .motif-label {
  font-family: 'ey-interstate-light', sans-serif;
  color: #2E2E38;
  margin-top: 1px;
}

.ql-toolbar.ql-snow {
  padding: 0 !important;
  height: 40px !important;
}

.ql-snow .ql-formats {
  margin: 0 !important;
  padding: 4px !important;
}

.motif-rich-text-editor .ql-toolbar.ql-snow .ql-formats button {
  margin-top: -3px !important;
}

.motif-form-field .motif-rich-text-editor p, .motif-form-field .motif-input:disabled {
  font-family: 'ey-interstate-light',sans-serif;
}

.motif-form-field.motif-form-field-select.motif-form-field-has-value .motif-label {
  padding: 0.2857142857rem 1rem !important;
  font-family: 'ey-interstate-light', sans-serif;
  font-size: 0.8571428571rem !important;
  line-height: 1.2142857143rem !important;
  margin-top: 1px;
}

.motif-form-field.motif-form-field-select .motif-form-field .motif-label {
  font-weight: var(--weight-light);
  font-family: 'ey-interstate-light',sans-serif;
  position: absolute !important;
  padding: 0.8571428571rem 1rem 0.2857142857rem 1rem;
}

.motif-form-field:not(.motif-form-field-date-picker):not(.motif-form-field-textarea):not(.motif-form-field-radio):not(.motif-form-field-time).motif-form-field-has-value .motif-select:not(.metrics-change-select) .motif-select-trigger {
  padding: 1.8571428571rem 1rem 0 1rem !important;
}

.industry-field-area .motif-form-field:not(.motif-form-field-date-picker):not(.motif-form-field-textarea):not(.motif-form-field-radio):not(.motif-form-field-time).motif-form-field-has-value .motif-select .motif-select-trigger {
  padding: 1.6428571429rem 2.5rem 1rem .7142857143rem !important;
}

:root .cdk-overlay-dark-backdrop {
  background: black !important;
}

:root .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.8 !important;
}

.bordercls::before {
  content: "";
  width: .0714285714rem;
  height: 2.8571428571rem;
  border-left: .0714285714rem var(--tree-line-style) var(--tree-line-color) !important;
  position: absolute;
  left: 1.1428571429rem;
  right: 0;
  bottom: -3.3571428571rem;
}

::ng-deep .motif-select-trigger .motif-select-arrow {
  top: 0.65rem !important;
  right: 1rem !important;
  color: #656579;
}

.select-renderer .motif-select-trigger {
  padding: 16px 10px !important;
}

.select-renderer.motif-form-field:not(.motif-form-field-date-picker):not(.motif-form-field-textarea):not(.motif-form-field-radio):not(.motif-form-field-time).motif-form-field-has-value .motif-select:not(.metrics-change-select) .motif-select-trigger {
  padding: 16px 10px !important;
}

.select-renderer .motif-select-trigger .motif-select-view-value{
  margin-bottom: 0 !important;
}

.select-renderer .motif-select-arrow {
  top: 9px !important
}

.motif-form-field.motif-form-field-typeahead .motif-input,
.motif-form-field .motif-form-field-typeahead .motif-input {
  padding: 1.8571428571rem 1rem .5714285714rem !important;
  padding-right: 30px !important;
}

.ag-theme-quartz .ag-paging-page-summary-panel span {
  font-size: 1rem !important;
  color: #2E2E38 !important;

}

.ag-theme-quartz .ag-paging-page-summary-panel span[ref="lbCurrent"] {
  margin-left: 0.5rem !important;

  &::after {
    content: '/';
    margin-left: 0.45rem;
  }
}

span[ref="lbTotal"] {
  margin-left: 0.2rem;
}

.ag-theme-quartz .ag-paging-page-summary-panel .ag-paging-button.ag-disabled .ag-icon {
  opacity: 0.5;
}

.ag-paging-page-summary-panel .ag-paging-button {
  margin: 0.5rem;
}


.ag-theme-quartz .ag-icon-first:before {
  content: url('/assets/navigation/fast-arrow-left.svg') !important;
  position: absolute;
  bottom: -0.1rem;
}

.ag-theme-quartz .ag-cell .ag-icon {
  background: transparent;
}

.ag-theme-quartz .ag-row-selected {
  background-color: rgba(21, 92, 180, 0.15) !important;
}

.ag-theme-quartz .ag-icon-previous:before {
  content: url('/assets/navigation/nav-arrow-left.svg') !important;
  position: absolute;
  bottom: -0.1rem;
}

.ag-theme-quartz .ag-icon-next:before {
  content: url('/assets/navigation/nav-arrow-right.svg') !important;
  position: absolute;
  bottom: -0.1rem;
}

.ag-theme-quartz .ag-icon-last:before {
  content: url("/assets/navigation/fast-arrow-right.svg") !important;
  position: absolute;
  bottom: -0.1rem;
}

.ag-paging-page-summary-panel {
  font-size: 0 !important;
}

.ag-paging-page-size {
  display: none;
}

.pagesize-container {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 13px;
  margin-bottom: 5px;
  display: flex;
  justify-content: flex-end;
  /* margin-top: -25px; */
  width: 75%;
  position: absolute;
  bottom: 0.20rem;
  margin-left: 8rem;
}

.ag-paging-row-summary-panel {
  display: none;
}

.ag-paging-description {
  font-size: 16px;
}

.ag-paging-description span:first-child {
  display: none;
}

.show-pagesize {
  position: absolute;
  right: 19rem;
  bottom: 0.8rem;
  font-size: 16px;
  width: 5rem;
  font-weight: 300;
}

#page-size {
  border: none;
}

.ag-sticky-bottom, .ag-paging-panel {
  border: 0 !important;
}

.users-list .ag-row {
  border-bottom: 0 !important;
  border-top: 1px solid #d9d9db !important;
}

.users-list .ag-header {
  border-radius: 7px 7px 0 0;
}

.user-mgmt-grid .ag-root-wrapper-body:has(.ag-overlay-no-rows-wrapper)
{
  height: 100px !important; 
}

.question-summary-table .ag-root-wrapper-body:has(.ag-overlay-no-rows-wrapper){
  height: 6.5rem!important;
} 

.ag-root-wrapper {
  border-color: #d7d7dc;
}

motif-expansion-panel+motif-expansion-panel .motif-expansion-panel-header,
motif-expansion-panel+motif-expansion-panel .motif-expansion-panel-header-alternative,
motif-expansion-panel+motif-expansion-panel .motif-expansion-panel-header.motif-expanded,
motif-expansion-panel+motif-expansion-panel .motif-expansion-panel-header-alternative.motif-expanded {
  border-top: 1px solid #D7D7DC !important;
}

.motif-tree .motif-nested-tree-node button.motif-tree-node-toggle,
.motif-tree .motif-tree-node button.motif-tree-node-toggle {
  margin: 0 !important;
}

.motif-error p {
  margin-bottom: 0 !important;
}

.motif-form-field .motif-error p:first-child {
  margin-top: 3px !important;
}

.motif-toast-info .motif-toast-content {
  font-family: 'ey-interstate-light', sans-serif;
  font-weight: bold !important;
}