/*Colors Styles*/
.ey-yellow {
    background-color:#FFE600;
}

.ey-linkblue {
    background-color:#336699;
}

.ey-red {
    background-color:#f04c3e;
}

.ey-buttercup {
    background-color:#fff27f;
}

.ey-blue {
    background-color:#00a3ae;
}
.ey-purple {
    background-color:#91278f;
}
.ey-green {
    background-color:#2c973e;
}
.ey-lilac {
    background-color:#ac98db;
}

.ey-blue-50 {
    background-color:#7fd1d6;
}
.ey-purple-50 {
    background-color:#c893c7;
}
.ey-green-50 {
    background-color:#95cb89;
}
.ey-lilac-50 {
    background-color:#d8d2e0;
}

/*GREYS*/
.charcoal {
    background-color:#333333;
}
.dark-grey {
    background-color:#646464;
}
.grey-34th {
    background-color:#808080;
}
.grey-half {
    background-color:#999999;    
}
.grey {
    background-color:#c0c0c0;
}
.light-grey {
    background-color:#f0f0f0;
}

.black {
    background-color:#000;
}