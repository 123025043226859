.alert {
    padding:20px 25px;
    border:0;
    border-radius:0;
    font-weight:bold;
}

.alert span.fa:first-child {
    margin-right:20px;
}

.alert-warning {
    background-color:#ffe600;
    color:#333;
}
.alert-danger{
    background-color:#f04c3e;
    color:#fff;
}
.alert-info {
    background-color:#00a3ae;
    color:#fff;
}
.alert-success {
    background-color:#2c973e;
    color:#fff;
}
