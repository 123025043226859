/* added for modal

.modal.modal-drawer {
    height: 75%;
}

.modal-body{
    display:table;
    height:100%;
    padding:20px 30px 20px 30px;
    width:100%;
}

.modal.modal-drawer {
  width: 100%;
  left: 0;
  margin: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.modal-open .modal {
	overflow-y: hidden;
}
.modal.modal-drawer.fade {
  -webkit-transition: opacity .3s linear, top .5s ease-out;
  -moz-transition: opacity .3s linear, top .5s ease-out;
  -ms-transition: opacity .3s linear, top .5s ease-out;
  -o-transition: opacity .3s linear, top .5s ease-out;
  transition: opacity .3s linear, top .5s ease-out;
  top: 100%;
}
.modal.modal-drawer.fade.in {
  top: 25%;
}
.drawer-container {
  height: 100%;
  position: relative; z-index:2000;
  background-color: #ffffff;
  overflow-y: hidden;
}
.drawer-side {
  width: 20%;
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
  background-color: #34383e;
  color: #ffffff;
}
.drawer-side-wrap {
  padding: 34px;
}
.drawer-side-wrap h1 {
  font-size: 48px;
  
}
.drawer-side-wrap .btn {
  margin-top: 30px;
}
.drawer-body {
  padding: 30px 30px;
  margin: 0 0 0 21%;
}
.drawer-content {
  overflow-y: hidden;
  height: 450px;
}

.drawer-content.tab-content .tab-pane  {
	background-color:#fff!important;
	width:73%;
}

.modal-align{
	display:none; 
	max-width:1600px; 
	margin:auto !important;
}

.modal .modal-content, 
.modal .modal-header,
.modal .modal-footer {
    border-radius:0;
    border:none;
}

.modal .modal-header {
    background-color:#fff;
    padding:30px 30px 0 30px;
    color:#333;
}

.modal .modal-header .fa-close {
    position:absolute;
    color:#cccccc;
    top:20px;
    right:20px;
    cursor:pointer;
}

.modal .modal-header .fa-close:hover {
    color:#c0c0c0;
}

.modal .modal-footer {
    padding:20px 30px 30px 30px;
    text-align:left;
}

.modal .modal-footer button {
    margin-left:0;
    margin-right:20px;
}

.modal .modal-footer hr{
    position:relative;
    width:100%;
    height:1px;
    background-color:#c0c0c0;
    top:-20px;
    margin:0;
} */