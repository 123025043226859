@charset "UTF-8";
/****** Fonts **********/

/* Material Icons*/
@font-face {
  font-family: 'MaterialIcons';
  font-style: normal;
  font-weight: 400;
  src: url('MaterialIcons-Regular.eot'); /* For IE6-8 */
  src: local('Material Icons'), local('MaterialIcons-Regular'),
    url('MaterialIcons-Regular.woff2') format('woff2'),
    url('MaterialIcons-Regular.woff') format('woff'),
    url('MaterialIcons-Regular.ttf') format('truetype');
}

.material-icons {
  font-family: 'MaterialIcons', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  color:#FFFFFF;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

/* NotoSans */

/* @font-face {
	font-family: 'NotoSansBold';
	src: url('NotoSans-Bold-webfont.eot');
	src: local('☺'), url('NotoSans-Bold-webfont.woff') format('woff'),
    url('NotoSans-Bold-webfont.ttf') format('truetype'),
    url('NotoSans-Bold-webfont.svg#noto_sansbold') format('svg');
	font-weight: normal;
	font-style: normal;
} */

@font-face {
	font-family: 'NotoSansBoldItalic';
	src: url('NotoSans-BoldItalic-webfont.eot');
	src: local('☺'), url('NotoSans-BoldItalic-webfont.woff') format('woff'), url('NotoSans-BoldItalic-webfont.ttf') format('truetype'), url('NotoSans-BoldItalic-webfont.svg#noto_sansbold_italic') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'NotoSansItalic';
	src: url('NotoSans-Italic-webfont.eot');
	src: local('☺'), url('NotoSans-Italic-webfont.woff') format('woff'), url('NotoSans-Italic-webfont.ttf') format('truetype'), url('NotoSans-Italic-webfont.svg#noto_sansitalic') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'NotoSans';
	src: url('NotoSans-Regular-webfont.eot');
	src: local('☺'), url('NotoSans-Regular-webfont.woff') format('woff'), url('NotoSans-Regular-webfont.ttf') format('truetype'), url('NotoSans-Regular-webfont.svg#noto_sansregular') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
    font-family: 'ey-interstate';
    src: url('ey-interstate-regular.ttf');
    src: url('ey-interstate-regular.ttf') format('ttf'), 
          url('ey-interstate-regular.woff2') format("woff2"), 
          url('ey-interstate-regular.woff') format("woff"), 
          url('ey-interstate-regular.svg') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  @font-face {
    font-family: 'ey_interstate';
    src: url('ey-interstate-regular.ttf');
    src: url('ey-interstate-regular.ttf') format('ttf'), 
          url('ey-interstate-regular.woff2') format("woff2"), 
          url('ey-interstate-regular.woff') format("woff"), 
          url('ey-interstate-regular.svg') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  @font-face {
    font-family: 'EYInterstate';
    src: url('ey-interstate-regular.ttf');
    src: url('ey-interstate-regular.ttf') format('ttf'), 
          url('ey-interstate-regular.woff2') format("woff2"), 
          url('ey-interstate-regular.woff') format("woff"), 
          url('ey-interstate-regular.svg') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  @font-face {
    font-family: 'ey-interstate-light';
    src: url('ey-interstate-light.eot');
    src: url('ey-interstate-light.eot') format('embedded-opentype'), 
          url('ey-interstate-light.woff2') format('woff2'), 
          url('ey-interstate-light.woff') format('woff'), 
          url('ey-interstate-light.svg') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
* {
    font-family: 'EYInterstate', sans-serif;
}

body{
    font-family: 'EYInterstate', sans-serif;
    font-size: 1.2vw;
    line-height: 1.65vw
}

h1, h2, h3, h4, h5,h6{
    font-family: 'EYInterstate', sans-serif;
    font-weight: bold;
}

h1{
    font-size:4.6vw;
    line-height:5.8vw;
}

h2{
    font-size:4vw;
    line-height:5.2vw;
}

h3{
    font-size:3.2vw;
    line-height:4.4vw;
}

h4{
    font-size:2vw;
    line-height:2.5vw;
}

h5{
    font-size:1.5vw;
    line-height:1.95vw;
}

h6{
    font-size:1.2vw;
    line-height:1.75vw;
}
