/* Tabs */
.tab-nav-group {    
	background-color: #fff;
    border-bottom:1px solid #c0c0c0; 
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none  
}

.tab-nav-group a.nav-tab {
    position:relative;
	display: inline-block;
    padding: 15px 20px;
    background-color:#f0f0f0;
    font-size:14px;
    cursor: pointer;
    color:#333;
    border: 1px solid #c0c0c0;
    border-bottom:none;
    top:1px;
}

.tab-nav-group > a.nav-tab:not(:first-child) {
    border-left:none;
}

.tab-nav-group a.nav-tab:hover {
   text-decoration:none;
}

.tab-nav-group > a.nav-tab.active {
    background-color: #fff;
    border-bottom:1px solid #fff;
}